<template>
  <div class="app-container">
    <!-- 查询开始 -->
    <div>
      <!--高级搜索输入框开始 -->
      <div v-if="show">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="timeValue"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions2"
              value-format="timestamp"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <!--高级搜索输入框结束 -->
      <!-- 普通搜索 -->

      <div class="searchheader">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="渠道名称">
            <el-input v-model="nameValue" placeholder="请输入渠道名称"></el-input>
          </el-form-item>
          <el-form-item label="商户号">
            <el-input v-model="searchId" placeholder="请输入商户号"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="stateValue" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="审核状态">
            <el-select v-model="examined" placeholder="请选择">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" plain round @click="search">搜索</el-button>
            <el-button
              @click="show = !show"
              plain
              type="primary"
              :icon="show ? 'el-icon-zoom-out':'el-icon-zoom-in'"
              round
              style="border:0;"
            >高级搜索</el-button>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" round plain @click="addWechat" icon="el-icon-circle-plus">创建</el-button>
        </div>
      </div>

      <!-- 普通搜索结束 -->
      <!-- 弹框 -->
      <el-dialog title="创建微信直连" :visible.sync="dialogFormVisible">
        <el-form :model="addWechatList" :rules="rule" ref="addWechatList">
          <el-form-item
            label="名称"
            :label-width="formLabelWidth"
            prop="mchName"
          >
            <el-input v-model="addWechatList.mchName" auto-complete="off" style="width:93%"></el-input>
          </el-form-item>
          <el-form-item
            label="服务商"
            :label-width="formLabelWidth"
            v-if="this.$store.state.user.level===1"
            prop="projectValue"
          >
            <el-select
              v-model="addWechatList.projectValue"
              placeholder="请输入关键字查询"
              clearable
              style="width:100%"
              filterable remote :remote-method="remoteMethod"
              @change="getShop(addWechatList.projectValue)"
            >
              <el-option
                v-for="item in projectList"
                :key="item.id"
                :label="item.label"
                :value="item.projectName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="商户"
            :label-width="formLabelWidth"
            v-if="this.$store.state.user.level !==3"
            prop="shopValue"
          >
            <el-select v-model="addWechatList.shopValue" clearable style="width:100%"
              filterable remote :remote-method="remoteShop" placeholder="请输入关键字查询">
              <el-option
                v-for="item in shopList"
                :key="item.id"
                :label="item.label"
                :value="item.shopName"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addSureWechat('addWechatList')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 查询结束 -->
    <!-- 表格开始 -->
    <div>
      <el-table
        border
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableDataList"
        style="width: 100%;"
        :height="this.show ?640:700"
        :row-class-name="tableRowClassName"
      >
       <el-table-column type="expand">
            <template slot-scope="scope">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="创建时间">
                  <span>{{ scope.row.createTime }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
        <el-table-column label="序号" width="60">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.num }}</span>
          </template>
        </el-table-column>
        <el-table-column label="渠道名称">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.mchName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所属商户">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.shopTbMap }}</span>
          </template>
        </el-table-column>
        <el-table-column label="微信特约子商户号">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.subMchId }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.state }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否审核">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.examined }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="280" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="DataEdit(scope.row)">编辑</el-button>
            <el-button
              size="mini"
              type="warning"
              plain
              @click="SwitchState(scope.row)"
            >{{scope.row.Disable}}</el-button>
            <el-button size="mini" type="success" plain @click="DataDetails(scope.row)" disabled>详情</el-button>
            <el-button size="mini" plain type="info" @click="DataCompletion(scope.row)">资料补全</el-button>
            <el-button size="mini" plain type="success" @click="Verification(scope.row)">支付验证</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 删除成功弹窗开始 -->
      <el-dialog title="删除" top="30vh" :visible.sync="dialogVisibleDel" width="15%">
        <span style="font-size:20px;">你确定要删除此条信息？请继续！</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleDel = false">取 消</el-button>
          <el-button type="primary" @click="SureDel">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 删除成功弹窗结束 -->
      <!-- 编辑开始 -->
      <el-dialog title="编辑微信直连" :visible.sync="DataEdits">
        <el-form :model="weChatList">
          <el-form-item
            label="名称"
            :label-width="formLabelWidth"
            prop="mchName"
            :rules="[{ required: true, message: '名称不能为空', trigger: 'blur' }]"
          >
            <el-input v-model="weChatList.mchName" auto-complete="off" style="width:93%"></el-input>
          </el-form-item>
          <el-form-item label="是否授权" :label-width="formLabelWidth">
            <el-select v-model="weChatList.authorized" placeholder="请选择" style="width:100%">
              <el-option
                v-for="item in authorization"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="审核状态" :label-width="formLabelWidth">
            <el-select
              v-model="weChatList.examined"
              placeholder="请选择"
              style="width:100%"
              :disabled="this.$store.state.user.level==3?true:false"
            >
              <el-option
                v-for="item in examinedData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="微信特约子商户号" :label-width="formLabelWidth">
            <el-input v-model="weChatList.subMchId" auto-complete="off" style="width:93%"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="DataEdits = false">取 消</el-button>
          <el-button type="primary" @click="DetermineEdit">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 编辑员工结束 -->
      <!-- 资料补全弹框开始 -->
      <el-dialog title="提交历史" :visible.sync="Tabledata" width="30%" center>
        <div
          style="display:flex;justify-content: space-around"
          v-for="(item,index) in SubmitData"
          :key="index"
        >
          <p style="border-bottom:1px solid;padding-bottom:5px;">{{item.fileName}}</p>
          <p>{{item.createTime}}</p>
          <p class="down" @click="downloadData(item)">下载</p>
        </div>
        <div>
          <el-upload
            ref="upload"
            class="upload-demo"
            action="/api/shop/uploadMaterial"
            :file-list="fileList"
            :auto-upload="false"
            :limit="1"
            accept=".xlsx, .txt, .docx"
            :data="uploadData"
            :on-success="uploadSuc"
            :with-credentials="true"
            :headers="headers"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传xlsx,txt,docx格式的文件</div>
          </el-upload>
          <el-input
            v-model="fileInput"
            size="mini"
            placeholder="请添加备注"
            style="width:34%;margin-top: 5px; "
          ></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="Tabledata = false">取 消</el-button>
          <el-button type="primary" @click="submitUpload">提交</el-button>
        </span>
      </el-dialog>
      <!-- 资料补全弹框结束 -->
      <!-- 详情弹框开始 -->
      <Details
        :DataDetail="DataDetail"
        :cencel="cencel"
        :applyWxTbData="applyWxTb"
        :weChatData="weChatData"
        :applyDescs="applyDescs"
        :getBankData="getBankData"
      />
      <!-- 详情弹框结束 -->
      <!-- 支付验证弹框开始 -->
      <el-dialog title="支付验证" :visible.sync="payEdits" top="15%" width="20%" center @close="close">
        <div id="qrcode" ref="qrcode"></div>
        <el-form :model="tradeId">
          <div class="payMoney">
            请用微信扫码支付
            <span>￥ {{payTotal}}</span>元
          </div>
          <el-form-item label="流水号" :label-width="formLabelWidth">
            <el-input v-model="tradeId.tradeId" auto-complete="off" style="width:93%"></el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 支付验证弹框结束 -->
    </div>
    <!-- 表格结束 -->
    <!-- 分页开始 -->
    <div style="text-align:right">
      <Paging :totalSize="totalSize" :getMethods="getWechat" />
    </div>
    <!-- 分页结束 -->
  </div>
</template>

<script>
import Query from "../../components/Query/Query.vue";
import Paging from "../../components/Paging/Paging.vue";
import { getToken } from "../../utils/auth.js";
import Details from "../components/details/details.vue";
import QRCode from "qrcodejs2";
export default {
  components: {
    Query,
    Paging,
    Details
  },
  data() {
    return {
      loading:true,
      // 搜索框
      input: "", //高级查询value值
      options: [
        {
          value: "1",
          label: "可用"
        },
        {
          value: "0",
          label: "禁用"
        },
        {
          value: "",
          label: "无"
        }
      ],
      options2: [
        //是否授权搜索框
        {
          value: "1",
          label: "审核通过"
        },
        {
          value: "0",
          label: "审核中"
        },
        {
          value: "-1",
          label: "未审核"
        },
        {
          value: "",
          label: "无"
        }
      ],
      authorization: [
        {
          value: 1,
          label: "是"
        },
        {
          value: 0,
          label: "否"
        }
      ],
      examinedData: [
        {
          value: -1,
          label: "未审核"
        },
        {
          value: 0,
          label: "审核中"
        },
        {
          value: 1,
          label: "审核通过" //审核通过
        }
      ],
      // 日期选择开始
      pickerOptions2: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      examined: "", //是否审核value值
      stateValue: "", //状态搜索框
      searchId: "", //搜索框商户号
      timeValue:null, //微信选中的日期
      nameValue: "", //搜索框商户名
      dialogFormVisible: false, //弹框值
      formLabelWidth: "80px", //弹框输入框宽度
      LabelWidth: "100px", //详情输入框宽度
      form: {
        //弹框表单
        projectName: "",
        phone: "",
        remark: "",
        address: ""
      },

      show: false,
      tableDataList: [],
      dialogVisibleDel: false, //删除弹窗
      tabelData: {}, //删除数据
      DataEdits: false, //编辑弹窗
      weChatList: {}, //编辑数据
      Tabledata: false, //资料补全
      CompletionMan: {}, //资料补全数据
      SubmitData: [],
      fileList: [], //上传文件列表
      fileInput: "", //上传文件输入框value
      headers: {
        //请求头token
        token: getToken().Token
      },
      projectList: [], //所有工程商

      shopList: [], //工程商下的商户
    
      addWechatList: { mchName: "" ,projectValue:'', shopValue: ""}, //创建数据
      totalSize: 0, //总条数
      DataDetail: false, //详情弹框
      applyWxTb: {}, //微信进件数据
      weChatData: {}, //点击的目标数据
      pageDatalist: {},

      applyDescs: {}, //详情查询的字段
      getBankData: {}, //详情查询的开户银行

      payEdits: false, //支付验证弹框
      tradeId: { tradeId: "" }, //流水号
      payTotal: 0,

      rule:{
        mchName:[{ required: true, message: '名称不能为空', trigger: 'blur' }],
        projectValue:[{ required: true, message: ' ', trigger: 'change' }],
        shopValue:[{ required: true, message: ' ', trigger: 'change' }]
      },

      queryProjectId:''
    };
  },
  computed: {
    uploadData() {
      //文件上传数据
      return {
        projectId: this.CompletionMan.projectContractorId,
        shopId: this.CompletionMan.shopId,
        remark: this.fileInput
      };
    }
  },
  methods: {
    // 获取订单
    getWechat(pageData) {
      this.pageDatalist = { ...pageData };
      // 时间转换
      let datalists = {
         pageConfig: {
            orderType: "desc",
            pageSize: this.pageDatalist.pageSize,
            orderField: "id",
            pageNum: this.pageDatalist.pageNum
          },
          baseBean:{
            state: this.stateValue,
            shopId: this.searchId,
            examined: this.examined
          }
      };
      // 判断有没有进行高级搜索，有就传入对应的时间，没有就不传
      if (this.timeValue !== null) {
        datalists.searchBeans= [
            //高级查询条件
            {
              fieldName: "createTime", //字段
              startValue:this.timeValue[0]/1000, //值
              endValue: this.timeValue[1]/1000,
              operator: "BETWEEN" //大于等于
            },
            {
              fieldName: "state", //字段
              startValue: "0", //值
              operator: "GREATER_THAN_AND_EQUAL" //大于等于
            }
          ]
      } else {
        // 名字查询
        if (this.nameValue !== "") {
          datalists.searchBeans= [
              //高级查询条件
              {
                fieldName: "state", //字段
                startValue: "0", //值
                operator: "GREATER_THAN_AND_EQUAL" //大于等于
              },
              {
                fieldName: "mchName", //查询字段
                basicValue: this.nameValue, //值
                operator: "LIKE" //模糊查询
              }
            ]
        } else {
          datalists.searchBeans= [
              //高级查询条件
              {
                fieldName: "state", //字段
                startValue: "0", //值
                operator: "GREATER_THAN_AND_EQUAL" //大于等于
              }
            ]
        }
        if (this.searchId !== "") {
          datalists.searchBeans= [
              //高级查询条件
              {
                fieldName: "state", //字段
                startValue: "0", //值
                operator: "GREATER_THAN_AND_EQUAL" //大于等于
              }
            ]
        }
      }

      this.$axios.post("/mchwx/query", datalists).then(res => {
        this.loading=false;
        this.totalSize = res.data.count;
        let datalist = res.data.mchWxtTbPage; //返回数据
        let number =
          (this.pageDatalist.pageNum - 1) * this.pageDatalist.pageSize; //分页时编号计算
        datalist.map(item => {
          number++;
          item.num = number;
          item.createTime = this.getTiem(item.createTime);
          item.shopTbMap = res.data.shopTbMap[item.shopId];
          if (item.state === 1) {
            item.state = "可用";
            item.Disable = "禁用";
          } else if (item.state === 0) {
            item.state = "禁用";
            item.Disable = "启用";
          } else {
            item.state = "删除";
          }
          if (item.examined === -1) {
            item.examined = "未审核";
          } else if (item.examined === 0) {
            item.examined = "审核中";
          } else {
            item.examined = "审核通过";
          }
          return item;
        });
        this.tableDataList = datalist;
      });
    },
    // 搜索
    search() {
      this.getWechat(this.pageDatalist);
    },
    remoteMethod(inputData){
      inputData!==''&& this.searchMerchant(inputData)
    },
    searchMerchant(inputData){
      // 获取工程商
      this.$axios.get("/projectContractor/queryAll",{params:{projectName:inputData}}).then(res => {
        this.projectList = res.data.projectTbPage;
      });
    },
    remoteShop(params){
      params!==''&& this.searchShop(params)
    },
    searchShop(params){
      this.$axios
        .get("/shop/queryAll", { params: { projectId:this.queryProjectId,shopName:params } })
        .then(res => {
          this.shopList = res.data.shopTbPage;
      });
    },
    // 删除功能暂时没用，功能已完成，只需要添加按钮
    DataDelete(row) {
      this.dialogVisibleDel = true;
      this.tabelData = row;
    },
    //确认删除
    SureDel() {
      const { id, shopId, projectContractorId } = this.tabelData;
      this.$axios
        .get("/mchwx/delete", {
          params: { mchId: id, shopId, projectId: projectContractorId }
        })
        .then(res => {
          if (res.data.state === 1) {
            this.getWechat(this.pageDatalist);
            this.DeleteSuccess();
            this.dialogVisibleDel = false;
          } else {
            this.DeleteFailure();
          }
        });
    },
    //禁启用商户
    SwitchState(row) {
      const { projectContractorId, shopId, id } = row;
      if (row.Disable === "禁用") {
        this.$axios
          .get("mchwx/disable", {
            params: { projectId: projectContractorId, shopId, mchId: id }
          })
          .then(res => {
            if (res.data.state === 1) {
              this.getWechat(this.pageDatalist);
              this.DeleteSuccess();
            }
          });
      } else if (row.Disable === "启用") {
        this.$axios
          .get("mchwx/enable", {
            params: { projectId: projectContractorId, shopId, mchId: id }
          })
          .then(res => {
            if (res.data.state === 1) {
              this.getWechat(this.pageDatalist);
              this.DeleteSuccess();
            }
          });
      }
    },
    // 资料补全
    DataCompletion(row) {
      this.CompletionMan = row;
      this.$axios
        .get("/shop/queryMaterialLog", {
          params: { shopId: row.shopId, projectId: row.projectContractorId }
        })
        .then(res => {
          this.SubmitData = res.data.shopMaterialTbPage.map(item => {
            item.createTime = this.getTiem(item.createTime);
            return item;
          });
          this.Tabledata = true;
        });
    },
    //下载
    downloadData(item) {
      this.$axios
        .get("/shop/downloadMaterial", {
          params: {
            shopId: item.shopId,
            projectId: item.projectContractorId,
            fileId: item.id
          },
          responseType: "blob"
        })
        .then(res => {
          const blob = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          let _fileName = res.headers["content-disposition"]
            .split(";")[1]
            .split("=")[1]
            .split(".")[0];
          // 文件名中有中文 则对文件名进行转码
          a.download = decodeURIComponent(_fileName);
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        });
    },
    // 文件上传
    submitUpload() {
      this.$refs.upload.submit();
    },
    // 上传成功提示
    uploadSuc(res) {
      if (res.state === 1) {
        this.DeleteSuccess();
      } else {
        this.DeleteFailure();
      }
    },
    // 编辑
    DataEdit(row) {
      this.weChatList = { ...row };
      this.DataEdits = true;
    },
    // 确认编辑
    DetermineEdit() {
      const {
        id,
        shopId,
        projectContractorId,
        subMchId,
        mchName,
        authorized,
        examined
      } = this.weChatList;
      let authorizeds;
      if (mchName !== "") {
        this.$axios
          .get("/mchwx/edit", {
            params: {
              mchId: id,
              shopId,
              projectId: projectContractorId,
              wxSubMchId: subMchId,
              mchName: mchName.trim(),
              examined,
              authorized
            }
          })
          .then(res => {
            if (res.data.state === 1) {
              this.getWechat(this.pageDatalist);
              this.DeleteSuccess();
              this.DataEdits = false;
            } else {
              this.DeleteFailure();
            }
          });
      } else {
        this.$message.error("请填写完整信息");
      }
    },
    //详情
    DataDetails(row) {
      this.weChatData = row;
      const { id, shopId, projectContractorId } = row;
      this.DataDetail = true;
      this.$axios
        .get("/mchwx/applyDetails", {
          params: { mchId: id, shopId, projectId: projectContractorId }
        })
        .then(res => {
          if (res.data.state === 1) {
            this.applyWxTb = res.data.applyWxTb;
          }
        });
      // 查询表单字段
      this.$axios.post("/mchwx/applyDesc").then(res => {
        if (res.data.state === 1) {
          this.applyDescs = res.data.applyDesc;
        }
      });
      // 查询基本户开户银行
      this.$axios.post("/mchwx/queryCardSupport").then(res => {
        if (res.data.state === 1) {
          this.getBankData = res.data.cardSupport;
        }
      });
    },
    // 取消详情弹框
    cencel() {
      this.DataDetail = false;
    },
    // 创建微信直连
    addWechat() {
      this.dialogFormVisible = true;
    },
    // 确认创建
    addSureWechat(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let pjtId, shopId;
          // 如果是系统层
          if (JSON.parse(sessionStorage.getItem("user")).level === 1) {
            this.projectList.forEach(item => {
              if (item.projectName === this.addWechatList.projectValue) {
                pjtId = item.id;
              }
            });
            this.shopList.forEach(item => {
              if (item.shopName === this.addWechatList.shopValue) {
                shopId = item.id;
              }
            });
            // 如果是服务商层
          } else if (JSON.parse(sessionStorage.getItem("user")).level === 2) {
            this.shopList.forEach(item => {
              if (item.shopName === this.addWechatList.shopValue) {
                shopId = item.id;
              }
            });
            pjtId = JSON.parse(sessionStorage.getItem("user")).projectContractorId;
            // 如果是商户层
          } else {
            pjtId = JSON.parse(sessionStorage.getItem("user")).projectContractorId;
            shopId = JSON.parse(sessionStorage.getItem("user")).shopId;
          }
          if (
            this.addWechatList.mchName !== "" &&
            pjtId !== undefined &&
            shopId !== undefined
          ) {
            this.$axios
              .get("/mchwx/create", {
                params: {
                  projectId: pjtId,
                  shopId,
                  mchName: this.addWechatList.mchName.trim()
                }
              })
              .then(res => {
                if (res.data.state === 1) {
                  this.getWechat(this.pageDatalist);
                  this.DeleteSuccess();
                  this.dialogFormVisible = false;
                } else {
                  this.DeleteFailure();
                }
              });
            this.dialogFormVisible = false;
          } else {
            this.$message.error("请填写完整信息！");
          }
          this.shopList = [];
          this.addWechatList.shopValue = ""; //清空input
          this.addWechatList.projectValue = "";
          this.addWechatList.mchName = "";
        } else {
          return false;
        }
      });
    },
    // 获取对应的商户
    getShop(value) {
      this.addWechatList.shopValue = ""; //清空商户input
      let projectId;
      this.projectList.forEach(item => {
        if (item.projectName === value) {
          this.queryProjectId = item.id;
        }
      });
    },
    // 支付验证
    Verification(row) {
      this.payEdits = true;
      let data = {
        mchId: row.id,
        shopId: row.shopId,
        projectId: row.projectContractorId
      };
      this.$axios.get("/mchwx/payVerify", { params: data }).then(res => {
        if (res.data.state == 1) {
          this.payTotal = res.data.total / 100;
          this.tradeId.tradeId = res.data.trade_id;
          const url = res.data.url;
          new QRCode("qrcode", {
            width: 120,
            height: 120, // 高度
            correctLevel: 3,
            text: url, // 二维码内容
            render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
            background: "#f0f",
            foreground: "#ff0"
          });
        }
      });
    },
    //关闭二维码弹框
    close() {
      document.querySelector("#qrcode").innerHTML = "";
      this.tradeId.tradeId = "";
    },
    // 时间转换
    getTiem(params) {
      let unixTimestamp = new Date(params * 1000); //时间戳转换成日期
      // 定义转换返回方式
      Date.prototype.toLocaleString = function() {
        return (
          this.getFullYear() +
          "年" +
          (this.getMonth() + 1) +
          "月" +
          +this.getDate() +
          "日" +
          " " +
          this.getHours() +
          "时" +
          this.getMinutes() +
          "分"
        );
      };
      params = unixTimestamp.toLocaleString();
      return params;
    },
    //操作成功提示
    DeleteSuccess() {
      this.$message({
        message: "恭喜你，操作成功！",
        type: "success"
      });
    },
    //操作失败提示
    DeleteFailure() {
      this.$message.error("操作失败");
    },
    // 表格颜色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "success-row";
      }
      return "";
    }
  }
};
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .cell {
  text-align: center;
}
#pag {
  position: fixed;
  bottom: 40px;
  right: 10px;
}

.el-select > .el-input {
  width: 93%;
}
.el-col-4 {
  width: 13.66667%;
}
.el-button--mini {
  padding: 5px 5px;
}
.el-table td {
  padding: 10px 0;
}
#qrcode {
  width: 120px;
  height: 150px;
  margin: 10px auto 0;
}
.payMoney {
  margin: 0 auto 10px;
  text-align: center;
}
.payMoney > span {
  font-size: 16px;
  color: red;
}
</style>